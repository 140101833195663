import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/Layout"
import MetaData from "../../components/meta"
import RelatedResourceCard from "../../components/RelatedResourceCard"

// Import styles
import "../../styles/api-gateway.scss"

// Images
import intro_simple_flexible from "../../images/api-gateway/intro/icons-hd-availability.svg"
import intro_scalable from "../../images/api-gateway/intro/icons-hd-scalability.svg"
import intro_infrastructure from "../../images/api-gateway/intro/icons-hd-cloud-infrastructure.svg"
import testimonials_logo from "../../images/api-gateway/testimonials/customer-logos-ebay.svg"
import testimonials_quote from "../../images/api-gateway/testimonials/quote-shape.svg"
import features_connect from "../../images/api-gateway/features/connect-encrypt.svg"
import features_traffic from "../../images/api-gateway/features/traffic-management.svg"
import features_auth from "../../images/api-gateway/features/authentication.svg"
import features_observability from "../../images/api-gateway/features/observability.svg"
import features_accelerate from "../../images/api-gateway/features/accelerate.svg"
import features_collaborate from "../../images/api-gateway/features/collaborate.svg"

const apiGatewayPage = ({ data, location }) => {
  const related_resources = data.related_resources.edges

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />

      <div className="api-gateway-page">
        {/* Hero */}
        <section className="api-gateway-hero page-hero">
          <div className="wrapper-1200">
            <div className="api-gateway-hero__content page-hero__content">
              <h1>API Gateway</h1>
              <p>Deploy, Secure, and Manage your microservices and APIs</p>
              <div className="api-gateway-hero__links">
                <a
                  href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days"
                  className="button--primary button--with-shadow button--extra-padding"
                >
                  Free Trial
                </a>
                <a
                  href="https://info.traefik.io/en/request-demo-traefik-enterprise"
                  className="button--primary button--primary--white button--with-shadow button--extra-padding"
                >
                  Request Demo
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Intro */}
        <section className="api-gateway-intro page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>What is an API Gateway?</h2>
              <p className="sub-title">
                Deployed at the edge of your infrastructure, the API Gateway is
                a single entry point that routes client API requests to your
                backend microservices.
              </p>
              <p className="sub-title">
                As an API Gateway, Traefik Enterprise provides key capabilities
                such as API security, traffic management, and observability.
                Traefik Enterprise enables security policies, adding user
                authentication and authorization, while also accelerating client
                requests through caching and traffic shaping. With embedded
                observability, Traefik collects real-time metrics and tracing
                for insight and troubleshooting of every single API request.
              </p>
              <div className="links">
                <Link
                  className="button--secondary button--secondary--with-arrow"
                  to="/blog/unlock-the-potential-of-data-apis-with-strong-authentication-and-traefik-enterprise/"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="api-gateway-intro__cards">
              <div className="api-gateway-intro__card">
                <div className="api-gateway-intro__card__wrapper">
                  <img src={intro_simple_flexible} alt="Simple & Flexible" />
                  <h3>Simple &amp; Flexible</h3>
                  <ul className="list--custom">
                    <li>
                      <p>Adapts to your current workflow</p>
                    </li>
                    <li>
                      <p>
                        Different patterns available (api gateway, service mesh,
                        ingress controller)
                      </p>
                    </li>
                    <li>
                      <p>
                        No need for third party databases or additional
                        components
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="api-gateway-intro__card">
                <div className="api-gateway-intro__card__wrapper">
                  <img src={intro_scalable} alt="Scalable" />
                  <h3>Scalable</h3>
                  <ul className="list--custom">
                    <li>
                      <p>No single point of failure</p>
                    </li>
                    <li>
                      <p>Highly-available controllers &amp; proxies</p>
                    </li>
                    <li>
                      <p>Scalability (adaptive scale-out)</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="api-gateway-intro__card">
                <div className="api-gateway-intro__card__wrapper">
                  <img
                    src={intro_infrastructure}
                    alt="Infrastructure Agnostic"
                  />
                  <h3>Infrastructure Agnostic</h3>
                  <ul className="list--custom">
                    <li>
                      <p>Runs on any infrastructure</p>
                    </li>
                    <li>
                      <p>
                        Any orchestrator, any monitoring &amp; tracing solution
                      </p>
                    </li>
                    <li>
                      <p>Cloud-native ready &amp; legacy compliant</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="api-gateway-testimonials page-content-section">
          <div className="wrapper-1200">
            <div className="api-gateway-testimonial">
              <img
                className="api-gateway-testimonial__logo"
                src={testimonials_logo}
                alt="Ebay"
              />
              <p className="api-gateway-testimonial__quote">
                "We needed an advanced load balancer and an API Gateway for
                advanced routing to control traffic flow with flexible
                configuration and automation capabilities. This complexity makes
                it difficult to find a good load balancer. A load balancer per
                domain was not enough for us. Traefik gives us flexibility and
                avoids any vendor lock-in."
              </p>
              <div className="api-gateway-testimonial__name">Wim Fournier</div>
              <div className="api-gateway-testimonial__designation">
                Site Reliability Engineer at eBay Classifieds Group (eCG)
              </div>
              <div className="api-gateway-testimonial__quotes">
                <img src={testimonials_quote} />
              </div>
            </div>
          </div>
        </section>

        {/* Features */}
        <section className="api-gateway-features page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Why use Traefik for API Management?</h2>
            </div>
            <div className="api-gateway-features__grid">
              <div className="api-gateway-feature">
                <img src={features_connect} alt="Connect & Secure" />
                <h3>Connect &amp; Secure</h3>
                <p>
                  Expose and connect your services right from the moment they’re
                  deployed, using any protocol, and automatically encrypt
                  communications with TLS, no effort needed.
                </p>
                <ul className="list--custom">
                  <li>
                    <p>HTTP, HTTP/2, TCP, UDP, Websockets, gRPC</p>
                  </li>
                  <li>
                    <p>Distributed Let's Encrypt</p>
                  </li>
                  <li>
                    <p>Vault integration</p>
                  </li>
                </ul>
              </div>
              <div className="api-gateway-feature">
                <img src={features_auth} alt="Authenticate & Authorize" />
                <h3>Authenticate &amp; Authorize</h3>
                <p>
                  Teams can offload the complexity of authentication and
                  authorization to the API Gateway, instantly securing existing
                  APIs in a consistent way.
                </p>
                <ul className="list--custom">
                  <li>
                    <p>LDAP authentication</p>
                  </li>
                  <li>
                    <p>JWT authentication</p>
                  </li>
                  <li>
                    <p>oAuth2 authentication</p>
                  </li>
                  <li>
                    <p>OpenID Connect (OIDC)</p>
                  </li>
                  <li>
                    <p>HMAC authentication</p>
                  </li>
                </ul>
              </div>
              <div className="api-gateway-feature">
                <img src={features_traffic} alt="Traffic Management" />
                <h3>Traffic Management</h3>
                <p>
                  Advanced traffic management features to support enterprise
                  application deployment at scale.
                </p>
                <ul className="list--custom">
                  <li>
                    <p>Traffic mirroring</p>
                  </li>
                  <li>
                    <p>Blue / Green & Canary deployments</p>
                  </li>
                  <li>
                    <p>Stickiness</p>
                  </li>
                  <li>
                    <p>Active health checks</p>
                  </li>
                  <li>
                    <p>Middlewares (circuit breakers, retries, buffering...)</p>
                  </li>
                  <li>
                    <p>Distributed In-flight request limiting</p>
                  </li>
                  <li>
                    <p>Distributed rate limiting</p>
                  </li>
                </ul>
              </div>
              <div className="api-gateway-feature">
                <img
                  src={features_observability}
                  alt="API Traffic Observability"
                />
                <h3>API Traffic Observability</h3>
                <p>
                  Real-time traffic metrics and analysis for rapid
                  troubleshooting and better insights into the health of your
                  microservices.
                </p>
                <ul className="list--custom">
                  <li>
                    <p>Cluster-wide dashboard</p>
                  </li>
                  <li>
                    <p>Distributed tracing (Jaeger, Open Tracing, Zipkin)</p>
                  </li>
                  <li>
                    <p>
                      Support for third-party monitoring platforms (Datadog,
                      Grafana, InfluxDB, Prometheus, StatsD)
                    </p>
                  </li>
                </ul>
              </div>
              <div className="api-gateway-feature">
                <img src={features_accelerate} alt="Accelerate" />
                <h3>Accelerate</h3>
                <p>
                  Integrate your API Gateway with GitOps workflows. Instantly
                  improve API response time with an embedded cache.
                </p>
                <ul className="list--custom">
                  <li>
                    <p>Cache</p>
                  </li>
                  <li>
                    <p>Integrate in a GitOps workflow</p>
                  </li>
                  <li>
                    <p>Continuously deliver services with no interruption</p>
                  </li>
                </ul>
              </div>
              <div className="api-gateway-feature">
                <img src={features_collaborate} alt="Collaborate" />
                <h3>Collaborate</h3>
                <p>
                  Documenting your APIs has never been easier with the embedded
                  developer portal.
                </p>
                <ul className="list--custom">
                  <li>
                    <p>Developer portal with OpenAPI (Swagger) support</p>
                  </li>
                  <li>
                    <p>Share knowledge</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Related Resources */}
        {related_resources.length > 0 && (
          <section className="api-gateway-resources page-content-section">
            <div className="wrapper-1200">
              <div className="page-content-section__header">
                <hr className="hr-tiny" />
                <h2>Related Resources</h2>
              </div>
              <div className="related-resource-feed">
                {related_resources.map(({ node }) => (
                  <RelatedResourceCard key={node.id} post={node} />
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
}

export default apiGatewayPage

export const query = graphql`
  query {
    related_resources: allGhostPost(
      filter: {
        tags: {
          elemMatch: { slug: { eq: "hash-api-gateway-related-resource" } }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }

    current_page: ghostPage(slug: { eq: "api-gateway" }) {
      ...PageMetaFields
    }
  }
`
